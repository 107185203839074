import React, { Component } from 'react';

class Aticle3 extends Component {
  constructor() {
    super();
    this.state = {
    };
  }

  render() {

    return (
      <div>


      <div className="container">
        <div className="texte">
          26.18.2030
      </div>
      <div className="texte2">
           scroll down
        </div>
      </div>
      <div className="vide">
allo3
      </div>
    </div>
    )
  }
}
export default Aticle3;