import React, { Component } from 'react';

class Aticle2 extends Component {
  constructor() {
    super();
    this.state = {
    };
  }

  render() {

    return (
      <div>


      <div className="container">
        <div className="texte">
          16.18.2020
      </div>
      <div className="texte2">
           scroll down
        </div>
      </div>
      <div className="vide">
allo2
      </div>
    </div>
    )
  }
}
export default Aticle2;